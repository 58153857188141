import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from '../components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const SingleUser = Loader(
  lazy(() => import('../content/user/single'))
);

const userRoutes = [
  {
    path: 'settings',
    element: <SingleUser />
  },
];

export default userRoutes;
