import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import router from './router';
import { useDispatch, useSelector } from './store';
import { connectToSocket, disconnectFromSocket } from './slices/socket';
import AppInit from './components/AppInit';
import useAuth from './hooks/useAuth';

function App() {

  const content = useRoutes(router);
  const auth = useAuth();
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.socket);

  useEffect(() => {
    dispatch(connectToSocket());
    
    return () => {
      if (socket && socket.connectionStatus === 'connected') {
        dispatch(disconnectFromSocket());
      }
    };
  }, [dispatch, socket]);

  return auth.isInitialized ? content : <AppInit />;
}
export default App;
