import Authenticated from '../components/Authenticated';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import userRoutes from './user';
import accountRoutes from './account';

import CollapsedSidebarLayout from '../layouts/CollapsedSidebarLayout';

import SuspenseLoader from '../components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const ComingSoon = Loader(
    lazy(() => import('../content/pages/ComingSoon'))
);


const router = [
    {
        path: '/',
        element: <ComingSoon />,
    },
    {
      path: 'account',
      children: accountRoutes
    },
    // Collapsed Sidebar Layout

    {
      path: 'casino',
      element: (
        <Authenticated>
          <CollapsedSidebarLayout />
        </Authenticated>
      ),
      children: [
        {
          path: 'casino',
          element: <Navigate to="user" replace />
        },
        {
          path: 'user',
          children: userRoutes
        },
        // {
        //   path: 'games',
        //   children: gameRoutes
        // }
      ]
    },
];

export default router;