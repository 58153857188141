import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import SmsIcon from '@mui/icons-material/Sms';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const menuItems = [
  {
    heading: 'General',
    items: [
      {
        name: 'Casino',
        icon: SmsIcon,
        link: '/casino/games',
      },
      {
        name: 'Crash',
        icon: AssignmentIndTwoToneIcon,
        link: 'casino/games/crash',
      },
      {
        name: 'Dice',
        icon: CalendarTodayIcon,
        link: '/casino/games/dice',
      },
      {
        name: 'Mines',
        icon: CalendarTodayIcon,
        link: '/casino/games/mines',
      }
      // {
      //   name: 'Business Reports',
      //   icon: AnalyticsTwoToneIcon,
      //   link: '/collapsed-sidebar/dashboards',
      //   items: [
      //     {
      //       name: 'Profit Margins',
      //       link: 'dashboards/expenses',
      //       badge: '',
      //     },
      //     {
      //       name: 'Sales Statistics',
      //       link: 'dashboards/statistics',
      //       badge: '',
      //     },
      //     {
      //       name: 'Website Analytics',
      //       link: 'dashboards/analytics'
      //     },
      //   ],
      // }
    ],
  },
];

export default menuItems;
