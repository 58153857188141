import { useContext } from 'react';
import Scrollbar from '../../../components/Scrollbar';
import { SidebarContext } from '../../../contexts/SidebarContext';

import { Box, Drawer, styled, useTheme } from '@mui/material';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  () => `
        width: 240px;
        top: 0px;
        color: #fff;
        background: #131521;
        position: fixed;
        height: 100%;
        z-index:999;
`
);

const TopSection = styled(Box)(
  () => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: 0 20px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: { xs: 'none', md: 'inline-block' }
        }}
        className='sidebar'
      >
        <TopSection>
          {/* LOGO HERE */}
          LOGO
        </TopSection>
        <Box
          sx={{
            height: 'calc(100% - 80px)'
          }}
        >
          <Scrollbar>
            <SidebarMenu />
          </Scrollbar>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          display: { md: 'none', xs: 'inline-block' }
        }}
        anchor={'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
