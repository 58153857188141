import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import SocketClient from './api/SocketClient';

export const socketClient = new SocketClient();

const root = document.getElementById('root');
createRoot(root).render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>
);
